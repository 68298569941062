<section class="qualification section">
    <h2 class="section__title">Portafolio</h2>
    <span class="section__subtitle">Trabajos recientes</span>
<!-- 
    <div class="portfolio__container containerPersonal swiper-container">

        <div>
            <h3 class="title_ser"> <i class="fas fa-laptop button__icon"></i> Aplicativos Webs</h3>
        </div>

        <div class="swiper-wrapper">

            <div class="  swiper-slide">
                <div class="portfolio__content grid">
                    <img src="assets/img/portfolio1.jpg" alt="" class="portfolio__img">
                    <div class="portfolio__data">
                        <h3 class="portfolio__title">Sitio web moderno</h3>
                        <p class="portfolio__description"> Sitio web adaptable para todos los dispositivos, con
                            componentes
                            ui e interacciones animadas.</p>
                        <a href="" class="button button--flex button--small portfolio__button">
                            Demo
                            <i class="fas fa-arrow-right button__icon"></i>
                        </a>
                    </div>
                </div>

            </div>


            <div class="swiper-slide">
                <div class="portfolio__content grid">
                    <img src="assets/img/portfolio2.jpg" alt="" class="portfolio__img">
                    <div class="portfolio__data">
                        <h3 class="portfolio__title">Diseño de la marca</h3>
                        <p class="portfolio__description"> Sitio web adaptable para todos los dispositivos, con
                            componentes
                            ui e interacciones animadas.</p>
                        <a href="" class="button button--flex button--small portfolio__button">
                            Demo
                            <i class="fas fa-arrow-right button__icon"></i>
                        </a>
                    </div>
                </div>

            </div>


            <div class=" swiper-slide">
                <div class="portfolio__content grid">
                    <img src="assets/img/portfolio1.jpg" alt="" class="portfolio__img">
                    <div class="portfolio__data">
                        <h3 class="portfolio__title">Tienda en linea</h3>
                        <p class="portfolio__description"> Sitio web adaptable para todos los dispositivos, con
                            componentes
                            ui e interacciones animadas.</p>
                        <a href="" class="button button--flex button--small portfolio__button">
                            Demo
                            <i class="fas fa-arrow-right button__icon"></i>
                        </a>
                    </div>
                </div>

            </div>

        </div>
        <div class="swiper-button-prev"><i class="fas fa-angle-left swiper-portfolio-icon"></i></div>
        <div class="swiper-button-next"><i class="fas fa-angle-right swiper-portfolio-icon"></i></div>
        <div class="swiper-pagination"></div>

    </div>


    <br><br><br>

    <div class="portfolio__container containerPersonal swiper-container">

        <div>
            <h3 class="title_ser"> <i class="far fa-mobile button__icon"></i> Aplicativos Mobiles</h3>
        </div>

        <div class="swiper-wrapper">

            <div class="  swiper-slide">
                <div class="portfolio__content grid">
                    <div class="portfolio__data">
                        <h3 class="portfolio__title">Sitio web moderno</h3>
                        <p class="portfolio__description"> Sitio web adaptable para todos los dispositivos, con
                            componentes
                            ui e interacciones animadas.</p>
                        <a href="" class="button button--flex button--small portfolio__button">
                            Demo
                            <i class="fas fa-arrow-right button__icon"></i>
                        </a>
                    </div>
                    <img src="assets/img/portfolio1.jpg" alt="" class="portfolio__img">
                    
                </div>

            </div>


            <div class="swiper-slide">
                <div class="portfolio__content grid">
                    <img src="assets/img/portfolio2.jpg" alt="" class="portfolio__img">
                    <div class="portfolio__data">
                        <h3 class="portfolio__title">Diseño de la marca</h3>
                        <p class="portfolio__description"> Sitio web adaptable para todos los dispositivos, con
                            componentes
                            ui e interacciones animadas.</p>
                        <a href="" class="button button--flex button--small portfolio__button">
                            Demo
                            <i class="fas fa-arrow-right button__icon"></i>
                        </a>
                    </div>
                </div>

            </div>


            <div class=" swiper-slide">
                <div class="portfolio__content grid">
                    <img src="assets/img/portfolio1.jpg" alt="" class="portfolio__img">
                    <div class="portfolio__data">
                        <h3 class="portfolio__title">Tienda en linea</h3>
                        <p class="portfolio__description"> Sitio web adaptable para todos los dispositivos, con
                            componentes
                            ui e interacciones animadas.</p>
                        <a href="" class="button button--flex button--small portfolio__button">
                            Demo
                            <i class="fas fa-arrow-right button__icon"></i>
                        </a>
                    </div>
                </div>

            </div>

        </div>
        <div class="swiper-button-prev"><i class="fas fa-angle-left swiper-portfolio-icon"></i></div>
        <div class="swiper-button-next"><i class="fas fa-angle-right swiper-portfolio-icon"></i></div>
        <div class="swiper-pagination"></div>

    </div> -->





    <div class="qualification__container containerPersonal">
        <div class="qualification__tabs">
            <div (click)="cambioOpenLIst('mobiles')" class="qualification__button button--flex "
                [ngClass]="{'qualification__active': openList === 'mobiles'}">
                <i class="far fa-mobile qualification__icon"></i>
                Mobiles
            </div>

            <div (click)="cambioOpenLIst('webs')" class="qualification__button button--flex"
                [ngClass]="{'qualification__active': openList === 'webs'}">
                <i class="fas fa-laptop qualification__icon"></i>
                Webs
            </div>
        </div>

        <div class="qualification__sections">
            <!-- cualificacion contenido 1 -->
            <div class="qualification__content" [ngClass]="{'qualification__active': openList === 'mobiles'}"
                data-content>

                <!-- <h3>hola</h3> -->

                <div class="services__container containerPersonal grid">
                    <div class="services__content" *ngFor="let service  of dataServices"  >
                        <div>
                            <img src="assets/sites/{{service.img}}" width="100%" class="img_apps">
                        </div>
                        <div>
                            <p class="about__descripcion"><b class="title_apps">{{service.titulo1}}</b> <br>   {{service.titulo2}}</p>
                        </div>
                        <br>
                        <span class="button button--flex button--small button--link services__button" >
                           <a href="{{service.link}}" target="_blank" style="text-decoration: none; outline: none !important;">Ver demostración</a> 
                            <i class="fas fa-play button__icon"></i>
                        </span>
                    </div>
                </div>

            </div>


            <!-- cualificacion contenido 1 -->
            <div class="qualification__content" [ngClass]="{'qualification__active': openList === 'webs'}"
                data-content>

                <div class="services__container containerPersonal grid">
                <div class="services__content" *ngFor="let service  of dataServicesWeb"  >
                    <div>
                        <img src="assets/sites/{{service.img}}" width="100%" class="img_apps">
                    </div>
                    <div>
                        <p class="about__descripcion"><b class="title_apps">{{service.titulo1}}</b> <br>   {{service.titulo2}}</p>
                    </div>
                    <br>
                    <span class="button button--flex button--small button--link services__button" href="{{service.link}}">
                        <a href="{{service.link}}" target="_blank">  Ver demostración </a> 
                        <i class="fas fa-play button__icon"></i>
                    </span>
                </div>
            </div>
            </div>


        </div>
    </div>




</section>