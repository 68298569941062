<footer class="footerPersonal">
    <div class="footer__bg">
        <div class="footer__container ">

            <h1 class="footer__title">Alex Laime</h1>
            <!-- <span class="footer__subtitle">Desarrollador frontend</span> -->

            <!-- <ul class="footer__links">
                <li>
                    <a href="#" class="footer__link">Servicios</a>
                </li>
            </ul> -->

            <!-- <div class="footer__socials">
                <a href="" class="footer__social" target="_blank">
                    <i class="fab fa-facebook-square"></i>
                </a>

                <a href="" class="footer__social" target="_blank">
                    <i class="fab fa-instagram-square"></i>
                </a>
            </div> -->
        </div>

        <p class="footer__copy">&#169; Laime developer. Todos los derechos reservados. </p>
    </div>
</footer>