







<section class="home section">
    <div class="home__container containerPersonal grid">
        <div class="home__content grid">
            <!-- <div class="home__social">
                <a href="#" target="_blank" class="home__social-icon"><i class="fab fa-facebook-square"> </i> </a>
                <a href="#" target="_blank" class="home__social-icon"><i class="fab fa-instagram"></i> </a>

            </div> -->
            <div class="home__img">
                
            
                    <ng-lottie   [options]="options" width="350px"
                    height="350px"></ng-lottie>
               

           

            </div>
            
            <div class="home__data">
                <h1 class="home__title">
                    Hola <small style="font-size: 30px;">soy</small> <br>
                    Alex Laime
                </h1>
                <h3 class="home__subtitle tw"></h3>
                <p class="home__description">Alto nivel y experiencia en el desarrollo frontend y backend <br> con tecnologías
                    de ultima generación.</p>
                <!-- <a href="" class="button button--flex"><i class="fas fa-comments button__icon"></i> Contactame</a> -->
               
                <a href="https://api.whatsapp.com/send?phone=51980861604&text=Hola%20Laime,%20qué%20tal%20me%20interesan%20tus%20servicios?"  target="_blank" class="button button--flex button--small portfolio__button animated fadeIn">
                    Contactar
                    <i class="fas fa-arrow-right button__icon"></i>
                </a>
               
            </div>
        </div>

    </div>
</section>