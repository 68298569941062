<section class="testimonial section">
    <h2 class="section__title">Testimonio</h2>
    <span class="section__subtitle">Mis clientes dicen</span>

    <div class="testimonial__container containerPersonal swiper-container mySwiper">
        <div class="swiper-wrapper">
            <!-- testimonio 1 -->
            <!-- <div class="swiper-slide"></div> -->

            <div class="swiper-slide">
                <div class="testimonial__content ">
                    <div class="testimonial__data">
                        <div class="testimonial__header">
                            <img src="assets/img/testimonial1.jpg" alt="" class="testimonial__img">
                            <div>
                                <h3 class="testimonial__name">Sara Smith</h3>
                                <span class="testimonial__client">Cliente</span>
                            </div>
                        </div>

                        <div>
                            <i class="far fa-star testimonial__icon-star"></i>
                            <i class="far fa-star testimonial__icon-star"></i>
                            <i class="far fa-star testimonial__icon-star"></i>
                            <i class="far fa-star testimonial__icon-star"></i>
                            <i class="far fa-star testimonial__icon-star"></i>
                        </div>
                    </div>

                    <p class="testimonial__description">Me llevo una buena impresion, realizo mi proyecto con toda la
                        calidad y atencion posible y soporte las 24 horas del dia.</p>
                </div>
            </div>


            <!-- testimonio  -->
            <div class="swiper-slide">
                <div class="testimonial__content">
                    <div class="testimonial__data">
                        <div class="testimonial__header">
                            <img src="assets/img/testimonial2.jpg" alt="" class="testimonial__img">
                            <div>
                                <h3 class="testimonial__name">Matt Robinson</h3>
                                <span class="testimonial__client">Cliente</span>
                            </div>
                        </div>

                        <div>
                            <i class="far fa-star testimonial__icon-star"></i>
                            <i class="far fa-star testimonial__icon-star"></i>
                            <i class="far fa-star testimonial__icon-star"></i>
                            <i class="far fa-star testimonial__icon-star"></i>
                            <i class="far fa-star testimonial__icon-star"></i>
                        </div>
                    </div>

                    <p class="testimonial__description">Me llevo una buena impresion, realizo mi proyecto con toda la
                        calidad y atencion posible y soporte las 24 horas del dia.</p>
                </div>
            </div>


            <!-- testimonio 3 -->
            <div class="swiper-slide">
                <div class="testimonial__content">
                    <div class="testimonial__data">
                        <div class="testimonial__header">
                            <img src="assets/img/testimonial3.jpg" alt="" class="testimonial__img">
                            <div>
                                <h3 class="testimonial__name">Raul Harris</h3>
                                <span class="testimonial__client">Cliente</span>
                            </div>
                        </div>

                        <div>
                            <i class="far fa-star testimonial__icon-star"></i>
                            <i class="far fa-star testimonial__icon-star"></i>
                            <i class="far fa-star testimonial__icon-star"></i>
                            <i class="far fa-star testimonial__icon-star"></i>
                            <i class="far fa-star testimonial__icon-star"></i>
                        </div>
                    </div>

                    <p class="testimonial__description">Me llevo una buena impresion, realizo mi proyecto con toda la
                        calidad y atencion posible y soporte las 24 horas del dia.</p>
                </div>
            </div>

        </div>
        <div class="swiper-pagination swiper-pagination-testimonial"></div>
    </div>
</section>