import {
  AfterViewInit,
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { SwiperComponent } from 'swiper/angular';
import SwiperCore, {
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
} from 'swiper/core';

SwiperCore.use([Navigation, Pagination, Mousewheel, Keyboard]);

import Swiper from 'swiper';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PortfolioComponent implements OnInit, AfterViewInit {

  public openList = 'mobiles';

  public dataServices =  [
    {
      titulo1: 'OPENAI - Chat GPT',
      titulo2: 'Chat GPT-3',
      icono: 'fas fa-code',
      color: '#F8F881',
      img: 'gtp3app.jpg',
      link: 'https://play.google.com/store/apps/details?id=com.laimedev.capacitor'
    },
    {
      titulo1: 'TLM APP Cliente',
      titulo2: 'Textil la Merced SAC',
      icono: 'fas fa-code',
      color: '#F8F881',
      img: 'tlmapp.jpg',
      link: 'https://play.google.com/store/apps/details?id=tlm.app.pe&hl=es'
    },
    {
      titulo1: 'QHATU Compras APP',
      titulo2: 'Amazonas Trading SAC',
      icono: 'fas fa-pencil-alt',
      color: '#A4F191',
      img: 'qhatuappppartner.jpg',
      link: 'https://play.google.com/store/apps/details?id=com.amazonastradingpartner.app'
    },
    {
      titulo1: 'QHATU Cacao APP',
      titulo2: 'Amazonas Trading SAC',
      icono: 'fas fa-th-large',
      color: '#B3A38D',
      img: 'qhatuappp.jpg',
      link: 'https://play.google.com/store/apps/details?id=com.amazonastrading.app'
    },
    {
      titulo1: 'DINDON APP',
      titulo2: 'Dindon SAC',
      icono: 'fas fa-code',
      color: '#B2B6CA',
      img: 'dindonapp.jpg',
      link: 'https://play.google.com/store/apps/details?id=com.deliverydindon.a001'
    },
    {
      titulo1: 'DINDON Store APP',
      titulo2: 'Dindon SAC',
      icono: 'fas fa-pencil-alt',
      color: '#B1EDDA',
      img: 'dindonappstore.jpg',
      link: 'https://play.google.com/store/apps/details?id=com.dindonnegocio'
    },
    {
      titulo1: 'DINDON Driver APP',
      titulo2: 'Dindon SAC',
      icono: 'fas fa-th-large',
      color: '#F7BBCE',
      img: 'dindonappdriver.jpg',
      link: 'https://play.google.com/store/apps/details?id=com.dindondriver.a002'
    },
    {
      titulo1: 'TMLM APP',
      titulo2: 'Te mando la moto SAC',
      icono: 'fas fa-pencil-alt',
      color: '#B1EDDA',
      img: 'tmlmapp.jpg',
      link: 'https://play.google.com/store/apps/details?id=com.temandolamoto.tmlm'
    },
    {
      titulo1: 'TMLM Driver',
      titulo2: 'Te mando la moto SAC',
      icono: 'fas fa-th-large',
      color: '#F7BBCE',
      img: 'paneltmlm.jpg',
      link: 'https://play.google.com/store/apps/details?id=com.temandolamoto.tmlmgo'
    }
  ];




  public dataServicesWeb =  [

    {
      titulo1: 'SURCO TENIS WEB',
      titulo2: 'Municipalidad de Surco',
      icono: 'fas fa-code',
      color: '#F8F881',
      img: 'surco_tenis.jpeg',
      link: 'https://surcotenis.pe'
    },
    {
      titulo1: 'RESERVA CON PASARELA DE PAGO',
      titulo2: 'Municipalidad de Surco',
      icono: 'fas fa-code',
      color: '#F8F881',
      img: 'reservas_surco_Tenis.jpeg',
      link: 'https://reservas.surcotenis.pe/#/reserve'
    },
    {
      titulo1: 'BOTICA SAN ANTONIO',
      titulo2: 'Policlinico',
      icono: 'fas fa-code',
      color: '#F8F881',
      img: 'botica_sanantonio.jpeg',
      link: 'https://botica.drugstorehelp.tech'
    },
    {
      titulo1: 'SISTEMA ODONTOLÓGICO',
      titulo2: 'Dental Children',
      icono: 'fas fa-code',
      color: '#F8F881',
      img: 'dental_childen.jpeg',
      link: 'https://dentalchildren.tech'
    },
    {
      titulo1: 'FACTURACIÓN ELECTRONICAA',
      titulo2: 'Veneimport sac',
      icono: 'fas fa-code',
      color: '#F8F881',
      img: 'veneimporta_facturacion.jpeg',
      link: '#'
    },
    {
      titulo1: 'CLAUDIA SERPA',
      titulo2: 'Artista Peruana',
      icono: 'fas fa-code',
      color: '#F8F881',
      img: 'claudiaserpa.jpg',
      link: 'https://claudiaserpaoficial.com'
    },
    {
      titulo1: 'ByV',
      titulo2: 'BYV IESEMIN SAC',
      icono: 'fas fa-code',
      color: '#F8F881',
      img: 'byv.jpg',
      link: 'https://byv.com.pe'
    },
    {
      titulo1: 'ALMACENES GM',
      titulo2: 'Alamacenes GM SAC',
      icono: 'fas fa-code',
      color: '#F8F881',
      img: 'almacenesgm.jpg',
      link: 'http://almacenesgm.com'
    },
    {
      titulo1: 'AMS Globals',
      titulo2: 'AMS Globals SAC',
      icono: 'fas fa-pencil-alt',
      color: '#A4F191',
      img: 'amsglobals.jpg',
      link: 'http://amsglobal.com.pe'
    },
    {
      titulo1: '2mt Studio',
      titulo2: '2mt Studio SAC',
      icono: 'fas fa-th-large',
      color: '#B3A38D',
      img: '2mt.jpg',
      link: 'http://2mt.com.pe'
    },
    {
      titulo1: 'General Studio',
      titulo2: 'General Studio SAC',
      icono: 'fas fa-code',
      color: '#B2B6CA',
      img: 'generalstudio.jpg',
      link: 'http://generalstudio.pe'
    },
    {
      titulo1: 'CEBA San Antonio',
      titulo2: 'Pronoep San Antonio',
      icono: 'fas fa-pencil-alt',
      color: '#B1EDDA',
      img: 'pronoepsanantonio.jpg',
      link: 'https://pronoepsanantonio.edu.pe'
    },
    {
      titulo1: 'Karisa Cosmeticos',
      titulo2: 'Karisa Cosmeticos SAC',
      icono: 'fas fa-th-large',
      color: '#F7BBCE',
      img: 'karsa.jpg',
      link: 'http://karisacosmeticos.com'
    },
    {
      titulo1: 'Home Premium',
      titulo2: 'Home Premium SAC',
      icono: 'fas fa-pencil-alt',
      color: '#B1EDDA',
      img: 'homeprimium.jpg',
      link: 'http://homepremium.com.pe'
    },
    {
      titulo1: 'Vene IMPORT',
      titulo2: 'Vene IMPORT SAC',
      icono: 'fas fa-th-large',
      color: '#F7BBCE',
      img: 'veneim.jpg',
      link: 'http://veneimportnataly.com'
    },
    {
      titulo1: 'Dulce Leona',
      titulo2: 'Dulce Leona SAC',
      icono: 'fas fa-th-large',
      color: '#F7BBCE',
      img: 'dulceleona.jpg',
      link: 'https://dulceleona.com'
    },
    {
      titulo1: 'Efel APP',
      titulo2: 'EFEL Club SAC',
      icono: 'fas fa-pencil-alt',
      color: '#B1EDDA',
      img: 'efelapp-borde.jpg',
      link: 'efelclub.com'
    },
    {
      titulo1: 'Buena Vida',
      titulo2: 'Oxividas SAC',
      icono: 'fas fa-th-large',
      color: '#F7BBCE',
      img: 'buenavida.jpg',
      link: 'http://oxividas.com.pe'
    },
    {
      titulo1: 'Hotel el FRAYLE',
      titulo2: 'Hotel el FRAYLE SAC',
      icono: 'fas fa-pencil-alt',
      color: '#B1EDDA',
      img: 'hotelelfrayle.jpg',
      link: 'http://hotelelfrayle.com'
    },
    {
      titulo1: 'Tienda Nina Rosse',
      titulo2: 'Nina Rosse SAC',
      icono: 'fas fa-th-large',
      color: '#F7BBCE',
      img: 'ninarosse.jpg',
      link: 'http://nina-rosse.com'
    }
  ];



  constructor() {}

  ngOnInit(): void {}
  cambioOpenLIst(nameList: string): void {
    this.openList = nameList;
  }
  
  ngAfterViewInit(): void {
    const swiper = new Swiper('.portfolio__container', {
      cssMode: true,
      // mousewheel: true,
      // keyboard: true,
      // slidesPerView: 1,
      // spaceBetween: 30,
      // loop: true,
      pagination: {
        el: '.swiper-pagination',
        // clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  }



 

}
