import { Component, OnInit } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import Typewriter from 't-writer.js'


@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {

  options: AnimationOptions = {
    path: 'assets/lottie/developer123.json'
  }

  constructor() { }

  ngOnInit(): void {

    const target = document.querySelector('.tw')

    const writer = new Typewriter(target, {
      loop: true,
      typeSpeed: 80,
      deleteSpeed: 80,
      typeColor: '#16CA91'
    })
    
    writer
      .type('Desarrollador de ')
      .rest(500)
      .changeOps({ deleteSpeed: 80 })
      .remove(0)
      .type(' Software')
      .rest(500)
      .remove(8)
      .type(' Aplicaciones Web')
      .rest(500)
      .changeOps({ deleteSpeed: 20 })
      .remove(16)
      .type(' Aplicaciones Móviles')
      .rest(500)
      .clear()
      .start()

  }

}
