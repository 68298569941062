<section class="about section">
    <h2 class="section__title">Acerca de mi</h2>
    <span class="section__subtitle">Mi Introducción</span>
    <div class="about__container containerPersonal grid">
        <!-- <img src="assets/img/about_gn.jpeg" alt="" class="about__img"> -->

        <div class="imagen-container">
            <img src="assets/img/about_bn.jpeg" alt="Imagen 1" class="imagen" id="imagen">
            <img src="assets/img/about_gn.jpeg" alt="Imagen 2" class="imagen" id="imagen2">
        </div>


        <div class="about__data">
            <p class="about__descripcion animated fadeIn">
                Hola mi nombre es Alex Laime, soy desarrollador  web y mobile con experiencia en backend, frontend y tecnologias de diseño.
            </p>
            <div class="about__info">
                <div>
                    <span class="about__info-title">+05</span>
                    <span class="about__info-name">Años <br> Experiencia</span>
                </div>
                <div>
                    <span class="about__info-title">+40</span>
                    <span class="about__info-name">Proyectos <br> Completados</span>
                </div>


            </div>
            <div class="about__buttons">
                <a download="" href="assets/pdf/__CV - Laime Soncco Alex_.pdf" class="button button--flex">
                    Descargar CV <i class="fas fa-download"></i>
                </a>
            </div>
        </div>
    </div>
</section>