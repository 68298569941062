import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss'],
})
export class ServiceComponent implements OnInit {

  public dataSkills: [] = [];

  public dataServices =  [

    {
      titulo1: 'Desarrollador',
      titulo2: 'Backend',
      icono: 'fas fa-code',
      skills: [
        {
          skill: 'NodeJS',
        },
        {
          skill2: 'PHP',
        },
        {
          skill3: 'Firebase',
        },
        {
          skill4: '',
        },
      ],
      other_img: 'assets/img/skills/backend_skills.png'
    },
    {
      titulo1: 'Desarrollador',
      titulo2: 'Frontend',
      icono: 'fas fa-pencil-alt',
      skills: [
        {
          skill: 'HTML',
        },
        {
          skill2: 'JavaScript',
        },
        {
          skill3: 'CSS',
        },
        {
          skill4: 'Angular',
        },
      ],
      other_img: 'assets/img/skills/frontend_skills.png'
    },
    {
      titulo1: 'Ui/Ux',
      titulo2: 'Diseñador',
      icono: 'fas fa-th-large',
      skills: [
        {
          skill: 'Figma',
        },
        {
          skill2: 'Sketch',
        },
        {
          skill3: 'Adobe Ilustrator',
        },
        {
          skill4: '',
        },
      ],
      other_img: 'assets/img/skills/disenio_skills.png'
    }
    
  ];

  constructor() {
    
  }

  ngOnInit(): void {
   
  }
}
