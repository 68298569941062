<section class="services section">
    <h2 class="section__title">Servicios</h2>
    <span class="section__subtitle">Tecnologías</span>



    <div class="   container__card ">
        <!-- servicio 1 -->
        <div class="card__father " *ngFor="let service  of dataServices">
            <div class="card">

                <div class="card__front">
                    <div class="body__card_front">
                        <i class="{{service.icono}} services__icon"></i>
                        <h3 class="contact__title" style="color: #242329">{{service.titulo1}} <br>  {{service.titulo2}}</h3>
                        <span class="button button--flex button--small button--link services__button">
                            Ver mas
                            <i class="fas fa-arrow-right button__icon"></i>
                        </span>
                    </div>
                </div>

                <div class="card__back">
                        <div class="body__card_back">
                            <h1 style="color: #242329">Skills</h1>
                            <!-- <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur ab quas recusandae voluptatum.</p> -->
                            <img [src]="service.other_img" alt="" width="100%" style="padding: 20px;">
                            <!-- <input type="button" [value]="service.titulo2"> -->
                        </div>
                </div>
                

            </div>
        </div>
    </div>



    
</section>