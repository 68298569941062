import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
declare var iziToast: any;
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  contactForm!: FormGroup;
  isTypePassword: boolean = true;

  constructor(public http: HttpClient) { 
    this.initForm();
  }

  ngOnInit(): void {
  }


  initForm() {
    this.contactForm = new FormGroup({
      nombres: new FormControl('', 
        {validators: [Validators.required]}
      ),
      email: new FormControl('', 
        {validators: [Validators.required]}
      ),
      telefono: new FormControl('', 
        {validators: [Validators.required]}
      ),
      mensaje: new FormControl('', 
        {validators: [Validators.required]}
      )
    });
  }


  onSubmit() {
    if(!this.contactForm?.valid) return;
    

    // console.log(this.contactForm.value);


    this.http.post('https://backgtp3.onrender.com/api/send_mail_laimedev', this.contactForm.value).subscribe( resp => {
      console.log(resp);
      iziToast.show({
      title: 'CORRECTO: Mensaje enviado.',
      class: 'text-light',
      position: 'topRight',
      titleColor: '#FFFFFF',
      color: '#5A43CB'
    })
      this.contactForm.reset();
    })
    
  }

  

}
