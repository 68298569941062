<section class="project secction">
    <div class="">
    <!-- <div class="project__bg"> -->
        <div class="project__container containerPersonal grid">
            <div class="project__data">
                <h2 class="project__title">Tienes un nuevo proyecto?</h2>
                <p class="project__description">Contactame ahora y tendras un 30% de descuento.</p>
                <a href="https://api.whatsapp.com/send?phone=51980861604&text=Hola%20Laime,%20qué%20tal%20me%20interesan%20tus%20servicios?" target="_blank" class="button button--flex button--white">
                    Contactame
                    <i class="fas fa-envelope-open-text project__icon button__icon"></i>
                </a>
            </div>
            <img src="assets/img/project.png" alt="" class="project__img">
        </div>
    </div>
</section>